// User
export const TODO_USER_UPDATE_INFO = 'TODO_USER_UPDATE_INFO'

export const TODO_UPDATE_DEVICE_INFO = 'TODO_UPDATE_DEVICE_INFO'

export const TODO_UPDATE_SETTING_INFO = 'TODO_UPDATE_SETTING_INFO'

// Modal
export const TODO_LAYOUT_Z_INDEX_MODAL = 'TODO_LAYOUT_Z_INDEX_MODAL'

export const TODO_TOGGLE_LAYOUT_ORDER_MODAL = 'TODO_TOGGLE_LAYOUT_ORDER_MODAL'

export const TODO_TOGGLE_LAYOUT_LOGIN_MODAL = 'TODO_TOGGLE_LAYOUT_LOGIN_MODAL'

export const TODO_TOGGLE_LAYOUT_VERIFY_EMAIL_MODAL = 'TODO_TOGGLE_LAYOUT_VERIFY_EMAIL_MODAL'

export const TODO_TOGGLE_LAYOUT_PAYMENT_MODAL = 'TODO_TOGGLE_LAYOUT_PAYMENT_MODAL'

export const TODO_TOGGLE_LAYOUT_DEVICE_MODAL = 'TODO_TOGGLE_LAYOUT_DEVICE_MODAL'

export const TODO_TOGGLE_LAYOUT_UPGRADE_MODAL = 'TODO_TOGGLE_LAYOUT_UPGRADE_MODAL'

// Modal data
export const TODO_SETTING_DATA_ORDER_MODAL = 'TODO_SETTING_DATA_ORDER_MODAL'

export const TODO_SETTING_DATA_PAYMENT_MODAL = 'TODO_SETTING_DATA_PAYMENT_MODAL'
