import { COLORS } from '../../constants/Constants'
import isEmpty from 'lodash/isEmpty'

// Random a number in range
export function randomRangeNumber(min, max) {
  const randomNum = Math.floor(Math.random() * (max - min + 1) + min)

  return randomNum
}

// Random string color by default color list
export function randomColor() {
  const index = randomRangeNumber(0, 7)
  const color = COLORS[index]

  return color
}

export function formatTitleNumber(index) {
  const strIndex = index < 10 ? `0${index}` : String(index)

  return strIndex
}

// Check a object is empty
export function isEmptyNull(value) {
  return typeof value === 'undefined' || value === null
}

// Check a dictionary object is empty
export function isEmptyObject(value) {
  return isEmptyNull(value) || Object.keys(value).length === 0
}

// Check a dictionary object is empty
export function isEmptyString(value) {
  return isEmptyNull(value) || value.length === 0
}

export function isEmptyObjectFirestoreData(value) {
  let result = isEmptyObject(value)
  if (result === false) {
    result = getObjectValueWithDefaultValue(value, '_id', '').length === 0
  }

  return result
}

export function getObjectValueWithDefaultValue(object, key, defaultValue) {
  let value = defaultValue
  if (!isEmptyObject(object)) {
    if (!isEmptyNull(object[key])) {
      value = object[key]
    }
  }

  return value
}

export function getFileExtension(fileName) {
  return fileName.substr(fileName.lastIndexOf('.') + 1)
}

export function getFileNameWithoutExtension(fileName) {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName
}

// Generate image name
export function generateImageName(prefix, imgName, time) {
  const name = getFileNameWithoutExtension(imgName)
  const ext = getFileExtension(imgName)
  const newFileName = `${name}-${time}.${ext}`

  return `${prefix}-${newFileName}`
}

// Parse local file to base64 data.
export function readUploadedFileAsBase64(inputFile) {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }
    temporaryFileReader.readAsDataURL(inputFile)
  })
}

// Valid image file type
export function validImageTypeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

  return isJpgOrPng
}

// Valid image file size
export function validImageSizeUpload(file) {
  const isLt5M = file.size / 1024 / 1024 < 5

  return isLt5M
}

// Valid image file properties
export function validImageUpload(file) {
  let message = ''
  const isJpgOrPng = validImageTypeUpload(file)
  if (!isJpgOrPng) {
    message = 'You can only upload JPG/PNG file!'
  }

  //
  if (message.length === 0) {
    const isLt5M = validImageSizeUpload(file)
    if (!isLt5M) {
      message = 'Image must smaller than 5MB!'
    }
  }

  return message
}

export function formatTimeToSecond(time) {
  let timeInSec = 0
  const split_time = time.split(':')
  if (split_time.length > 0) {
    const strHour = split_time[0]
    if (strHour.length > 0) {
      const hour = parseInt(strHour)
      timeInSec += hour * 60 * 60
    }

    //
    if (split_time.length > 1) {
      const strMinute = split_time[1]
      if (strMinute.length > 0) {
        const minute = parseInt(strMinute)
        timeInSec += minute * 60
      }
      if (split_time.length > 2) {
        const strSecond = split_time[2]
        if (strSecond.length > 0) {
          //
          const split_second = strSecond.split(',')
          if (split_second.length > 0) {
            const strSecond1 = split_second[0]
            const second = parseInt(strSecond1)
            timeInSec += second
          }
        }
      }
    }
  }

  return timeInSec
}

export function hiddenPartEmail(email) {
  const partialEmail = email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2')
  return partialEmail
}

export const isMaintaining = () => {
  //
  let isTrueMaintaining = false
  const isMaintaining = process.env.NEXT_PUBLIC_IS_MAINTAINING
  if (isMaintaining) {
    isTrueMaintaining = String(isMaintaining).toLowerCase() === 'true'
  }

  return isTrueMaintaining
}

export const isAnalyze = () => {
  //
  let isTrueAnalyzing = false
  const isAnalyzing = process.env.NEXT_PUBLIC_IS_ANALYZE
  if (isAnalyzing) {
    isTrueAnalyzing = String(isAnalyzing).toLowerCase() === 'true'
  }

  return isTrueAnalyzing
}

export function checkTwoStringForResult(inputData, outputData) {
  let newInputData = inputData || ''
  newInputData = newInputData.trim()
  if (newInputData[newInputData.length - 1] === '.') newInputData = newInputData.slice(0, -1)
  //
  let newOutputData = outputData || ''
  newOutputData = newOutputData.trim()
  if (newOutputData[newOutputData.length - 1] === '.') newOutputData = newOutputData.slice(0, -1)
  return newInputData.toLowerCase().localeCompare(newOutputData.toLowerCase()) === 0
}

export function checkIndexTwoStringForResult(inputData, outputData) {
  let indexWord = 0
  //
  let arrayInputData = []
  if (!isEmpty(inputData)) {
    arrayInputData = inputData.trim().split(' ')
  }
  let arrayOutputData = []
  if (!isEmpty(outputData)) {
    arrayOutputData = outputData.trim().split(' ')
  }
  //
  if (arrayOutputData.length === 0) {
    if (arrayInputData.length === 0) {
      indexWord = -1
    }
  } else {
    if (arrayInputData.length > 0) {
      const maxArray = arrayOutputData.length > arrayInputData.length ? arrayOutputData.length : arrayInputData.length
      let isCorrect = true
      for (let i = 0; i < maxArray; i++) {
        if (i >= arrayInputData.length || i >= arrayOutputData.length) {
          indexWord = i
          isCorrect = false
          break
        } else {
          const stringInputData = arrayInputData[i]
          const stringOutputData = arrayOutputData[i]
          if (stringInputData.toLowerCase() !== stringOutputData.toLowerCase()) {
            indexWord = i
            isCorrect = false
            break
          }
        }
      }
      //
      if (isCorrect === true) {
        indexWord = -2
      }
    }
  }
  //
  return indexWord
}

export function calculateWords(value) {
  let l = 0
  if (!isEmpty(value)) {
    const splitArr = value.split(' ')
    l = splitArr.length
  }

  return l
}

export function calculateReadingTime(value) {
  let time = 0
  if (!isEmpty(value)) {
    const wpm = 225
    const l = calculateWords(value)
    time = Math.ceil(l / wpm)
  }

  return time
}

export function getVoicesByEnglishLanguage(voices) {
  let avaiableVoice = []
  if (!isEmpty(voices)) {
    avaiableVoice = voices.filter(voiceItem => {
      const lang = voiceItem.lang
      const localService = voiceItem.localService
      const name = voiceItem.name
      const voiceURI = voiceItem.voiceURI
      let valid = false
      if (lang === 'en-GB' || lang === 'en-US') {
        if (!isEmpty(voiceURI) && !isEmpty(name) && localService === true) {
          valid = true
        }
      }
      return valid
    })
  }

  return avaiableVoice
}

export function isNumeric(value) {
  return /^-?\d+$/.test(value)
}

export function stripHTML(originalString) {
  let strippedString = ''
  if (!isEmpty(originalString)) {
    strippedString = originalString.replace(/(<([^>]+)>)/gi, '')
  }
  return strippedString
}

export function substringLength(originalString, length) {
  let strippedString = ''
  if (!isEmpty(originalString)) {
    const maxLength = originalString.length < length ? originalString.length : length
    strippedString = originalString.substring(0, maxLength)
  }

  return strippedString
}

export function parseDropboxURLIfNeed(url) {
  let newURL = ''
  if (url) {
    newURL = url.replace('www.dropbox.com', 'dl.dropbox.com')
    newURL = newURL.replace('dl=0', 'dl=1')
  }

  return newURL
}

export function isAudioMP3Link(url) {
  console.log('isAudioMP3Link url:', url)
  let isAudio = false

  if (url) {
    if (url.indexOf('.mp3') >= 0 || url.indexOf('dropbox') >= 0) {
      isAudio = true
    }
  }

  return isAudio
}
