import firebase_app from '../firebaseConfig'
import { getFirestore, collection, doc, setDoc, getDoc, getDocs, writeBatch } from 'firebase/firestore'

const db = getFirestore(firebase_app)

export async function addData(collectionName, id, data) {
  let result = null
  let error = null

  try {
    result = await setDoc(doc(db, collectionName, id), data, {
      merge: true
    })
  } catch (e) {
    error = e
  }

  return { result, error }
}

export async function getDocument(collectionName, id) {
  let docRef = doc(db, collectionName, id)

  let result = null
  let error = null

  try {
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      result = docSnap.data()
    } else {
      console.log('Document does not exist')
    }
  } catch (e) {
    error = e
  }

  return { result, error }
}

export async function getAllDocument(collectionName) {
  let docRef = collection(db, collectionName)

  let result = []
  let error = null

  try {
    const docSnap = await getDocs(docRef)
    docSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      result.push(doc.data())
    })
  } catch (e) {
    error = e
  }

  return { result, error }
}

export async function updateAllBatch(docs, collectionName) {
  // prepare the batch
  let currentBatch = writeBatch(db)
  let currentBatchSize = 0
  const batches = [currentBatch]
  // add each doc's deletion to the batch
  docs.forEach(docData => {
    // when batch is too large, start a new one
    if (++currentBatchSize >= 500) {
      currentBatch = writeBatch(db)
      batches.push(currentBatch)
      currentBatchSize = 1
    }
    // add operation to batch
    const docRef = doc(db, collectionName, docData._id)
    currentBatch.update(docRef, docData)
  })
  // commit the changes
  return await Promise.all(
    batches.map(async batch => {
      return await batch
        .commit()
        .then(() => {
          return Promise.resolve(true)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          return Promise.resolve(false)
        })
    })
  )
}
