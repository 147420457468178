import { useState, useEffect, useRef } from 'react'

import firebase_app from '../services/firebaseConfig'

import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  getAuth,
  sendEmailVerification,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  updatePassword,
  signInAnonymously
} from 'firebase/auth'

//  Services
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { removeDeviceFromList } from 'src/@core/data/services/userService'
import { useSettings } from 'src/@core/hooks/useSettings'
import { useAccountData } from '../services/firestore/hooks/useAccountData'
import { useTranslation } from 'next-i18next'
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js'

const auth = getAuth(firebase_app)

//  Utils

// ** Constant
import { LIGHT_THEME_KEY, VI_LANGUAGE_KEY, DEVICE_USER_DATA_FIELD } from 'src/constants/Constants'

const formatAuthUser = user => ({
  uid: user.uid,
  email: user.email,
  emailVerified: user.emailVerified,
  isAnonymous: user.isAnonymous
})

export default function useFirebaseAuth() {
  // ** State
  const [authUser, setAuthUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [dbUser, setDBUser] = useState(null)
  const [fingerPrint, setFingerPrint] = useState(null)
  // ** Ref
  const dbUserRef = useRef()
  dbUserRef.current = dbUser

  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const accountDataHook = useAccountData()
  const { i18n } = useTranslation()
  // const deviceData = useDeviceData()

  const authStateChanged = async authState => {
    if (!authState) {
      setAuthUser(null)
      setLoading(false)
      //
      signInAnonymously(auth)
        .then(() => {
          // Signed in..
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          // ...
          console.log('errorCode:', errorCode)
          console.log('errorMessage:', errorMessage)
        })
    } else {
      setLoading(true)
      var formattedUser = formatAuthUser(authState)
      // console.log('formattedUser:', formattedUser)
      setAuthUser(formattedUser)
      setLoading(false)
      if (formattedUser.isAnonymous !== true) {
        // Firestore
        accountDataHook.actions.subscribeMyUserData(formattedUser.uid)
      }
    }
  }

  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged)

    getCurrentBrowserFingerPrint().then(fingerprint => {
      // console.log('fingerprint:', fingerprint)
      setFingerPrint(String(fingerprint))
    })
    return () => unsubscribe()
    // eslint-disable-next-line
  }, [])

  // listen for Firebase state change
  useEffect(() => {
    if (!isEmpty(accountDataHook.userInfo)) {
      //
      checkDeviceValidation(accountDataHook.userInfo)
    } else {
      setDBUser(accountDataHook.userInfo)
    }
    // eslint-disable-next-line
  }, [accountDataHook.userInfo, fingerPrint])

  async function checkDeviceValidation(newUserData) {
    let validUser = true
    //
    const oldUserData = dbUserRef.current

    if (!isEmpty(oldUserData)) {
      const fieldNameFinger = DEVICE_USER_DATA_FIELD.FINGER_PRINT
      //
      //
      if (!isEqual(oldUserData, newUserData)) {
        let oldFingerPrint = []
        if (oldUserData[fieldNameFinger]) {
          oldFingerPrint = [...oldUserData[fieldNameFinger]]
        }
        let newFingerPrint = []
        if (newUserData[fieldNameFinger]) {
          newFingerPrint = [...newUserData[fieldNameFinger]]
        }
        //
        if (
          !isEmpty(oldFingerPrint.find(item => item === fingerPrint)) &&
          isEmpty(newFingerPrint.find(item => item === fingerPrint))
        ) {
          validUser = false
          await signOutAccount()
        }
      }
    }
    if (validUser === true) {
      setDBUser(newUserData)
    }
  }

  // listen for Firebase state change
  useEffect(() => {
    if (!isEmpty(authUser) && !isEmpty(dbUser)) {
      if (authUser.emailVerified === true && dbUser.emailVerified === false) {
        accountDataHook.actions.updateMyAccountInfo(dbUser._id, { emailVerified: true })
      }
    }

    //
    if (!isEmpty(dbUser) && !isEmpty(settings)) {
      let newMode = LIGHT_THEME_KEY
      if (!isEmpty(dbUser.theme)) {
        newMode = dbUser.theme
      }
      const mode = settings.mode
      if (newMode !== mode) {
        saveSettings({ ...settings, mode: newMode })
      }

      //
      let newLanguage = VI_LANGUAGE_KEY
      if (!isEmpty(dbUser.language)) {
        newLanguage = dbUser.language
      }
      const language = i18n.language
      if (newLanguage !== language) {
        i18n.changeLanguage(newLanguage)
      }
    }
    // eslint-disable-next-line
  }, [authUser, dbUser])

  const signInUserEmail = async (email, password) => {
    let result = null,
      error = null
    try {
      result = await signInWithEmailAndPassword(auth, email, password)
    } catch (e) {
      error = e
    }

    return { result, error }
  }

  const createUserEmail = async (email, password) => {
    let result = null,
      error = null
    try {
      result = await createUserWithEmailAndPassword(auth, email, password)
    } catch (e) {
      console.log('e:', e)
      error = e
    }

    return { result, error }
  }

  const verifyUserEmail = async () => {
    let result = null,
      error = null
    try {
      result = await sendEmailVerification(auth.currentUser)
    } catch (e) {
      console.log('e:', e)
      error = e
    }

    return { result, error }
  }

  // eslint-disable-next-line no-unused-vars
  const signInUserGoogleProvider = async (email, password) => {
    let result = null,
      error = null
    try {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({ prompt: 'select_account' })
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      result = await signInWithPopup(auth, provider)
    } catch (e) {
      error = e
    }

    return { result, error }
  }

  const signInUserFacebookProvider = async () => {
    let result = null,
      error = null

    try {
      const provider = new FacebookAuthProvider()

      provider.setCustomParameters({ display: 'popup' })
      provider.addScope('public_profile,email')
      result = await signInWithPopup(auth, provider)
    } catch (e) {
      error = e
      console.log('error:', error)
    }

    return { result, error }
  }

  const fetchSignInMultipleProvider = async email => {
    let result = null,
      error = null
    try {
      // Get the sign-in methods for this email.
      const methods = await fetchSignInMethodsForEmail(auth, email)

      result = methods
    } catch (e) {
      error = e
      console.log('error:', error)
    }

    return { result, error }
  }

  const resetPasswordEmail = async email => {
    let result = null,
      error = null
    try {
      // Get the sign-in methods for this email.
      await sendPasswordResetEmail(auth, email)
    } catch (e) {
      error = e
      console.log('error:', error)
    }

    return { result, error }
  }

  const changePasswordEmail = async newPassword => {
    let result = null,
      error = null
    try {
      const user = auth.currentUser

      // Get the sign-in methods for this email.
      await updatePassword(user, newPassword)
    } catch (e) {
      error = e
      console.log('error:', error)
    }

    return { result, error }
  }

  const clear = () => {
    setAuthUser(null)
    setLoading(false)
    // setDBUser(null)
    accountDataHook.actions.clearAllSubscribe()
  }

  const signOutAccount = async () => {
    let result = null,
      error = null
    try {
      result = await signOut(auth)

      //
      clear()
    } catch (e) {
      error = e
      console.log('error:', error)
    }

    return { result, error }
  }

  const removeDeviceAccount = async () => {
    let result = null,
      error = null
    try {
      const fieldNameFinger = DEVICE_USER_DATA_FIELD.FINGER_PRINT
    
      const newFingerPrint = {}
      newFingerPrint[fieldNameFinger] = fingerPrint
      console.log('newFingerPrint:', newFingerPrint)
      const newInfo = removeDeviceFromList(dbUser, newFingerPrint)
      if (!isEmpty(newInfo)) {
        await accountDataHook.actions.updateMyAccountInfo(dbUser._id, newInfo)
      }
    } catch (e) {
      error = e
      console.log('error:', error)
    }

    return { result, error }
  }

  return {
    authUser,
    loading,
    dbUser,
    fingerPrint,
    signInUserEmail,
    createUserEmail,
    verifyUserEmail,
    signInUserGoogleProvider,
    signInUserFacebookProvider,
    fetchSignInMultipleProvider,
    resetPasswordEmail,
    changePasswordEmail,
    signOutAccount,
    removeDeviceAccount
  }
}
