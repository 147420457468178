/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
// eslint-disable-next-line
import produce from 'immer'
import { HYDRATE } from 'next-redux-wrapper'

import { TODO_UPDATE_FINGER_PRINT, TODO_UPDATE_DEVICE_INFO } from '../actions/type'

// The initial state of the App
export const initialState = {
  fingerPrint: "",
  deviceInfo: {}
}

/* eslint-disable default-case, no-param-reassign */
const deviceReducer = (state = initialState, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration
    }

    return nextState
  } else {
    return produce(state, draft => {
      switch (action.type) {
        case TODO_UPDATE_FINGER_PRINT:
          draft.fingerPrint = action.fingerPrint
          break
        case TODO_UPDATE_DEVICE_INFO:
          draft.deviceInfo = action.deviceInfo
          break
      }
    })
  }
}

export default deviceReducer
