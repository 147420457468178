//
import { isEmptyString } from './utils'

import moment from 'moment'

export function formatStringFromDay(date) {
  let strFormat = ''
  if (!isEmptyString(date)) {
    //
    const iDate = parseInt(date)
    const dateTime = new Date(iDate)

    //
    const month = dateTime.getMonth() + 1 // months from 1-12
    const day = dateTime.getDate()
    const year = dateTime.getFullYear()

    //
    const strDay = day < 10 ? `0${day}` : String(day)
    const strMonth = month < 10 ? `0${month}` : String(month)

    //
    strFormat = `${strDay}/${strMonth}/${year}`
  }

  return strFormat
}

export function formatYYYYMMDDStringFromDay(date) {
  let strFormat = ''
  if (!isEmptyString(date)) {
    //
    const iDate = parseInt(date)
    const dateTime = new Date(iDate)

    //
    const month = dateTime.getMonth() + 1 // months from 1-12
    const day = dateTime.getDate()
    const year = dateTime.getFullYear()

    //
    const strDay = day < 10 ? `0${day}` : String(day)
    const strMonth = month < 10 ? `0${month}` : String(month)

    //
    strFormat = `${year}-${strMonth}-${strDay}`
  }

  return strFormat
}

export function formatMonthYearStringFromDay(date) {
  let strFormat = ''
  if (!isEmptyString(date)) {
    //
    const iDate = parseInt(date)
    const dateTime = new Date(iDate)

    //
    const month = dateTime.getMonth() + 1 // months from 1-12
    const year = dateTime.getFullYear()

    //
    const strMonth = month < 10 ? `0${month}` : String(month)

    //
    strFormat = `${strMonth}/${year}`
  }

  return strFormat
}

export function calculateNumberDayFromNow(expiredDate) {
  let totalDays = 0
  if (expiredDate.length > 0) {
    const current = new Date().getTime()
    const expired = parseInt(expiredDate)
    if (expired > current) {
      const difference = expired - current
      totalDays = Math.ceil(difference / (1000 * 3600 * 24))
    } else {
      if (expired === -1) {
        totalDays = -1
      }
    }
  }

  return totalDays
}

export function isVipMember(expiredDate) {
  let isVip = false
  const totalDays = calculateNumberDayFromNow(expiredDate)
  if (totalDays > 0) {
    isVip = true
  } else if (totalDays === -1) {
    isVip = true
  }

  return isVip
}

export function appendNumberMonthFromDate(expiredDate, numberOfMonth) {
  let newDate = -1
  //
  let from = new Date()
  if (numberOfMonth !== -2) {
    if (expiredDate.length > 0) {
      if (expiredDate !== '-1') {
        const current = new Date().getTime()
        const expired = parseInt(expiredDate)
        if (expired > current) {
          from = new Date(expired)
        }
        newDate = from.setMonth(from.getMonth() + numberOfMonth)
      }
    }
  }

  return newDate
}

//
export function isToday(date) {
  // return moment(date).format('DD/MM/YYYY') == moment(new Date()).format('DD/MM/YYYY')
  const today = moment()

  return moment(date).isSame(today, 'day')
}

export function isYesterday(date) {
  const yesterday = moment().subtract(1, 'day')

  return moment(date).isSame(yesterday, 'day')
}

export function isWeek(date) {
  const today = moment()

  return moment(date).isSame(today, 'week')
}

export function dayWeek(translation, date, isFullText) {
  let strDay = ''
  const weekdayNumber = moment(date).isoWeekday()
  switch (weekdayNumber) {
    case 1:
      strDay =
        isFullText === true ? translation('date.last_mess_full_sunday') : translation('date.last_mess_small_sunday')
      break
    case 2:
      strDay =
        isFullText === true ? translation('date.last_mess_full_monday') : translation('date.last_mess_small_monday')
      break
    case 3:
      strDay =
        isFullText === true ? translation('date.last_mess_full_tuesday') : translation('date.last_mess_small_tuesday')
      break
    case 4:
      strDay =
        isFullText === true
          ? translation('date.last_mess_full_wednesday')
          : translation('date.last_mess_small_wednesday')
      break
    case 5:
      strDay =
        isFullText === true ? translation('date.last_mess_full_thursday') : translation('date.last_mess_small_thursday')
      break
    case 6:
      strDay =
        isFullText === true ? translation('date.last_mess_full_friday') : translation('date.last_mess_small_friday')
      break
    case 7:
      strDay =
        isFullText === true ? translation('date.last_mess_full_saturday') : translation('date.last_mess_small_saturday')
      break
    default:
      strDay = ''
  }

  return strDay
}

export function numberYears(date) {
  const mDate = moment(date)
  const mNow = moment(new Date())
  const years = mNow.diff(mDate, 'year')

  return years
}

export function numberMonths(date) {
  const mDate = moment(date)
  const mNow = moment(new Date())
  const months = mNow.diff(mDate, 'months')

  return months
}

export function numberWeeks(date) {
  const mDate = moment(date)
  const mNow = moment(new Date())
  const weeks = mNow.diff(mDate, 'week')

  return weeks
}

export function numberDays(date) {
  const mDate = moment(date)
  const mNow = moment(new Date())
  const days = mNow.diff(mDate, 'days')

  return days
}

export function numberHours(date) {
  const mDate = moment(date)
  const mNow = moment(new Date())
  const hours = mNow.diff(mDate, 'hours')

  return hours
}

export function numberMinutes(date) {
  const mDate = moment(date)
  const mNow = moment(new Date())
  const minutes = mNow.diff(mDate, 'minutes')

  return minutes
}

export function numberSeconds(date) {
  const mDate = moment(date)
  const mNow = moment(new Date())
  const seconds = mNow.diff(mDate, 'seconds')

  return seconds
}

export function offsetLong(translation, date) {
  const years = numberYears(date)
  if (years > 0) {
    return years > 1 ? `${String(years)} ${translation('date.years_ago')}` : translation('date.year_ago')
  }
  //
  const months = numberMonths(date)
  if (months > 0) {
    return months > 1 ? `${String(months)} ${translation('date.months_ago')}` : translation('date.month_ago')
  }
  const weeks = numberWeeks(date)
  if (weeks > 0) {
    return weeks > 1 ? `${String(weeks)} ${translation('date.weeks_ago')}` : translation('date.week_ago')
  }
  const hours = numberHours(date)
  if (hours > 0) {
    return hours > 1 ? `${String(hours)} ${translation('date.hours_ago')}` : translation('date.an_hour_ago')
  }
  const minutes = numberMinutes(date)
  if (minutes > 0) {
    return minutes > 1 ? `${String(minutes)} ${translation('date.minutes_ago')}` : translation('date.a_minute_ago')
  }
  const seconds = numberSeconds(date)
  if (seconds > 0) {
    return seconds > 1 ? `${String(seconds)} ${translation('date.seconds_ago')}` : translation('date.second_ago')
  }

  return translation('date.now')
}

export function timeStampHourMinute(date) {
  const mDate = moment(date)
  const timelabel = mDate.format('HH:mm')

  return timelabel
}

export function timeStampForDate(translation, date, showMinute, showTodayTimeStamp) {
  //
  let timeLabel = ''
  if (isToday(date)) {
    if (showTodayTimeStamp === true) {
      const timeOffset = offsetLong(translation, date) // "9M"
      timeLabel = timeOffset
    } else {
      timeLabel = translation('date.today')
    }
  } else {
    if (isYesterday(date)) {
      timeLabel = translation('date.yesterday')
    } else if (isWeek(date)) {
      timeLabel = dayWeek(date, true)
    } else {
      timeLabel = formatStringFromDay(String(date.getTime()))
    }
    //
    if (showMinute === true) {
      const strMinute = timeStampHourMinute(date)

      timeLabel += ` ${strMinute}`
    }
  }

  return timeLabel
}

export function getMultipleFirstOfMonthByRange(startDate, endDate) {
  const allDays = []
  if (startDate < endDate) {
    const numberOfMonth = 1
    let itemDay = firstOfMonth(startDate)
    do {
      allDays.push(new Date(itemDay.getTime()))
      //
      itemDay = new Date(itemDay.setMonth(itemDay.getMonth() + numberOfMonth))
    } while (itemDay < endDate)
  }
  return allDays
}

export function getMultipleFirstOfYearByRange(startDate, endDate) {
  const allDays = []
  if (startDate < endDate) {
    const numberOfYear = 1
    let itemDay = firstOfYear(startDate)
    do {
      allDays.push(new Date(itemDay.getTime()))
      //
      itemDay = new Date(itemDay.setFullYear(itemDay.getFullYear() + numberOfYear))
      //
    } while (itemDay < endDate)
  }
  return allDays
}

export function firstOfMonth(currentDate) {
  const day = moment(currentDate ?? new Date()).startOf('month')
  const date = new Date(day.valueOf())
  return date
}

export function lastOfMonth(currentDate) {
  const day = moment(currentDate).endOf('month')
  const date = new Date(day.valueOf())
  return date
}

export function firstOfYear(currentDate) {
  const day = moment(currentDate ?? new Date()).startOf('year')
  const date = new Date(day.valueOf())
  return date
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export function formatStringFullDate(date) {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
  )
}

export function convertMsToTime(secs, hideHours = true) {
  let finalTime = ''
  //
  if (secs) {
    var hours = Math.floor(secs / (60 * 60))

    var divisor_for_minutes = secs % (60 * 60)
    var minutes = Math.floor(divisor_for_minutes / 60)

    var divisor_for_seconds = divisor_for_minutes % 60
    var seconds = Math.ceil(divisor_for_seconds)

    if (hideHours === false || hours > 0) {
      finalTime = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    } else {
      finalTime = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    }
  }

  return finalTime
}

export function firstDayInMonthFromDate(date) {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)

  return firstDayOfMonth
}

export function lastDayInMonthFromDate(date) {
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  return lastDayOfMonth
}

export function firstDayInYearFromDate(date) {
  const firstDayOfMonth = new Date(date.getFullYear(), 0, 1)

  return firstDayOfMonth
}

export function lastDayInYearFromDate(date) {
  const lastDayOfMonth = new Date(date.getFullYear(), 11, 31)

  return lastDayOfMonth
}
