import { createContext, useContext } from 'react'
import useFirebaseAuth from '../hooks/useFirebaseAuth'

const authUserContext = createContext({
  authUser: null,
  dbUser: null,
  loading: true,
  fingerPrint: '',
  signInUserEmail: async () => {},
  createUserEmail: async () => {},
  verifyUserEmail: async () => {},
  signInUserGoogleProvider: async () => {},
  signInUserFacebookProvider: async () => {},
  fetchSignInMultipleProvider: async () => {},
  resetPasswordEmail: async () => {},
  changePasswordEmail: async () => {},
  signOutAccount: async () => {},
  removeDeviceAccount: async () => {}
})

export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth()

  return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>
}

// custom hook to use the authUserContext and access authUser and loading
export const useAuth = () => useContext(authUserContext)
