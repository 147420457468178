// ** Next Imports
import dynamic from 'next/dynamic'
import React from 'react'

import { Analytics } from '@vercel/analytics/react'
import Script from 'next/script'

// import { Provider as AuthProvider } from 'next-auth/react'

// ** Localization
import { appWithI18Next } from 'ni18n'
import { ni18nConfig } from '../../ni18n.config'

import { Router } from 'next/router'

// ** Redux
import { createWrapper } from 'next-redux-wrapper'
import { Provider } from 'react-redux'
import configureStore from '../@core/redux/store'

import { SnackbarProvider } from 'notistack'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
//
const initialState = {}
const store = configureStore(initialState)

//makeStore function that returns a new store for every request
// eslint-disable-next-line no-unused-vars
const makeStore = context => store
const wrapper = createWrapper(makeStore, { debug: false })

// ** Authentication
import { AuthUserProvider } from '../@core/context/AuthUserContext'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'

import SEOHeader from 'src/views/seo/head'

// ** Config Imports
import themeConfig from 'src/configs/themeConfig'

// ** Component Imports

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// import { GlobalStyles } from './theme/globalStyles'

// ** Global css styles
import '../../styles/globals.css'
import '@fullcalendar/common/main.css'
// import '@fullcalendar/daygrid/main.css'
// import '@fullcalendar/timegrid/main.css'
//  ** Global modal

import { isMaintaining } from 'src/@core/utils/utils.js'

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

// ** Configure JSS & ClassName
const App = appProps => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, ...rest } = appProps

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>)

  //  Redux
  const { store } = wrapper.useWrappedStore(rest)

  return (
    <CacheProvider value={emotionCache}>
      <SEOHeader />
      <React.Suspense fallback={<LoadingSimpleView />}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RE_CAP_CHA_SITE_KEY}
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined // optional, default undefined
          }}
        >
          <Provider store={store}>
            <SettingsProvider>
              {/* <AuthProvider session={pageProps.session}> */}
              <AuthUserProvider>
                <SnackbarProvider maxSnack={3} classes={{ containerRoot: 'z-alert' }}>
                  <SettingsConsumer>
                    {({ settings }) => {
                      return (
                        <ThemeComponent settings={settings}>
                          {isMaintaining() === false ? (
                            getLayout(
                              <>
                                <Script id='show-zalo-plugin' async strategy='lazyOnload'>
                                  {`if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
                                    {if(document.getElementById("linkzalo")) {document.getElementById("linkzalo").href="https://zalo.me/0395608813"};
                                    window.zaloJSV2 = {
                                      zalo_h5_event_handler: function (eventId, eventName, eventData) {}
                                    };}`}
                                </Script>
                                <Component {...pageProps} />
                                <Analytics />
                                <ZaloPlugin />
                              </>
                            )
                          ) : (
                            <MaintainPage />
                          )}
                        </ThemeComponent>
                      )
                    }}
                  </SettingsConsumer>
                </SnackbarProvider>
              </AuthUserProvider>
              {/* </AuthProvider> */}
            </SettingsProvider>
          </Provider>
        </GoogleReCaptchaProvider>
      </React.Suspense>
    </CacheProvider>
  )
}

// ** Authentication
const UserLayout = dynamic(() => import('src/layouts/UserLayout'))
const ThemeComponent = dynamic(() => import('src/@core/theme/ThemeComponent'))
//
const LoadingSimpleView = dynamic(() => import('src/@core/layouts/components/shared-components/LoadingSimpleView'))
const MaintainPage = dynamic(() => import('./maintain'))
const ZaloPlugin = dynamic(() => import('src/@core/layouts/components/shared-components/ZaloPlugin'))

export default appWithI18Next(App, ni18nConfig)
