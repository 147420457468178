import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createReducer from '../reducers'

export default function configureStore(initialState = {}) {
  const composeEnhancers = compose
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const middleWares = [sagaMiddleware]

  //
  const enhancers = [applyMiddleware(...middleWares)]

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers))
  // eslint-disable-next-line
  if (module.hot) {
    // eslint-disable-next-line
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}
