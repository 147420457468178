import firebase_app from '../firebaseConfig'
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore'

const db = getFirestore(firebase_app)

// Constants
import { COLLECTION_NAME } from '../../../constants/Constants'

// User
export const subscribeUserData = async (userId, callback) => {
  const userColRef = collection(db, COLLECTION_NAME.USER)

  // Create a query against the collection.
  const q = query(userColRef, where('_id', '==', userId))

  const unsubscribe = onSnapshot(
    q,
    querySnapshot => {
      const users = []
      querySnapshot.forEach(doc => {
        users.push(doc.data())
      })

      let userData = {}
      if (users.length > 0) {
        userData = users[0]
      }
      callback(userData, null)
    },
    error => {
      // ...
      callback(null, error)
    }
  )

  return unsubscribe
}
