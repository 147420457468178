import { combineReducers } from 'redux'

import userReducer from './userReducer'
import modalReducer from './modalReducer'
import deviceReducer from './deviceReducer'

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,
    device: deviceReducer,
    ...injectedReducers
  })

  return rootReducer
}
