import Head from 'next/head'
// import Script from 'next/script'

const DOMAIN = 'https://ielts-song-ngu.com'
const FAVICON = 'https://ielts-song-ngu.com/images/favicon.png'
const DEFAULT_OG_IMAGE = 'https://ielts-song-ngu.com/images/misc/og-site-banner.png'

export default function SEOHeader({
  title = 'IELTS Prediction - Cộng đồng học tập, trau dồi bài thi IELTS Quốc tế',
  description = 'IELTS Prediction - Cộng đồng học tập, ôn luyện, trau dồi bài thi IELTS Quốc tế 🚀 – Nguồn tham khảo uy tín cho học viên.',
  siteName = 'IELTS Prediction',
  canonical = DOMAIN,
  ogImage = DEFAULT_OG_IMAGE,
  ogType = 'website'
}) {
  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no'
        />

        <title key='title'>{`${title} – ${siteName}`}</title>
        <meta name='description' content={description} />
        <meta
          name='keywords'
          content='IELTS song ngữ, Học IELTS miễn phí, Cộng đồng review thi IELTS, Học IELTS cơ bản đến nâng cao, Tiếng Anh song ngữ'
        />
        <meta key='og_type' property='og:type' content={ogType} />
        <meta key='og_title' property='og:title' content={title} />
        <meta key='og_description' property='og:description' content={description} />
        <meta key='og_locale' property='og:locale' content='en_IE' />
        <meta key='og_site_name' property='og:site_name' content={siteName} />
        <meta key='og_url' property='og:url' content={canonical ?? DOMAIN} />
        <meta key='og_site_name' property='og:site_name' content={siteName} />
        <meta key='og_image' property='og:image' content={ogImage ?? DEFAULT_OG_IMAGE} />
        <meta key='og_image:alt' property='og:image:alt' content={`${title} | ${siteName}`} />
        <meta key='og_image:width' property='og:image:width' content='720' />
        <meta key='og_image:height' property='og:image:height' content='480' />
        <meta property='og:image:alt' content={title} />
        <meta name='twitter:card' content='Summary' />
        <meta name='twitter:title' content={siteName} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:url' content={canonical ?? DOMAIN} />
        <meta name='twitter:image' content={ogImage ?? DEFAULT_OG_IMAGE} />
        <link rel='icon' href='image/favicon.png' />
        <link rel='canonical' href={canonical ?? DOMAIN} />
        <link rel='shortcut icon' href={FAVICON} />
      </Head>
    </>
  )
}
