import HttpBackend from 'i18next-http-backend'
import ChainedBackend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'

const VERSION_LANGUAGE = 'v2.2'
const isBrowser = typeof window !== 'undefined'

/** To avoid issues when deploying to some paas (vercel...) */
const localePath = !isBrowser ? require('path').resolve('./', './public/locales') : '/locales'

export const ni18nConfig = {
  supportedLngs: ['en', 'vi'],
  defaultLocale: 'vi',
  ns: ['common'],
  localeDetection: false,
  react: {
    useSuspense: true
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  use: isBrowser ? [ChainedBackend] : undefined,
  localePath: isBrowser ? `${localePath}/{{lng}}/{{ns}}.json?v=${VERSION_LANGUAGE}` : undefined,
  backend: isBrowser
    ? {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            expirationTime: 7 * 24 * 60 * 60 * 1000,
            versions: { en: VERSION_LANGUAGE, vi: VERSION_LANGUAGE }
          },
          {
            loadPath: `${localePath}/{{lng}}/{{ns}}.json?v=${VERSION_LANGUAGE}`
          }
        ]
      }
    : undefined
}
