import { useRef, useState, useEffect } from 'react'

// Services
import { subscribeUserData } from '../user'
import { addData } from '../index'

// Utils
import isEmpty from 'lodash/isEmpty'

// Constants
import { COLLECTION_NAME } from '../../../../constants/Constants'

export const useAccountData = () => {
  // Ref
  const unsubscribeMyUserDataRef = useRef(null)

  // State
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    //
    return () => {
      // Cleanup here
      clearAllSubscribe()
    }
  }, [])

  // User info
  async function subscribeMyUserData(userId) {
    if (unsubscribeMyUserDataRef.current) {
      unsubscribeMyUserDataRef.current && unsubscribeMyUserDataRef.current()
    }

    const unsubscribe = await subscribeUserData(userId, (userData, error) => {
      if (error) {
        console.log('loi du lieu subscribeUserData:', error)
      } else if (!isEmpty(userData)) {
        setUserInfo(userData)
      }
    })
    unsubscribeMyUserDataRef.current = unsubscribe
  }

  // Action
  async function updateMyAccountInfo(userId, newData) {
    //
    const resultUpdated = await addData(COLLECTION_NAME.USER,userId,newData)
    
    return resultUpdated
 }


  function clearAllSubscribe() {
    // Sub
    if (unsubscribeMyUserDataRef.current) {
      unsubscribeMyUserDataRef.current && unsubscribeMyUserDataRef.current()
    }

    //
    setUserInfo({})
  }

  return {
    userInfo,
    actions: {
      subscribeMyUserData,
      updateMyAccountInfo,
      clearAllSubscribe
    }
  }
}
