export const USER_INFO_KEY = 'user_info_key'

export const MIN_LENGTH = {
  PASSWORD: 8,
  NAME: 2,
  BLOG_TITLE: 20,
  BLOG_CONTENT: 50
}

export const MAX_LENGTH = {
  PASSWORD: 8,
  NAME: 30,
  LOGIN: 3,
  BLOG_TITLE: 200,
  BLOG_CONTENT: 5000,
  DEVICE: 5,
  UPDATE_LAST_TIME: 5
}

export const LOCAL_STORAGE_KEY = {
  //App
  NAV_SECTION_SETTINGS: 'nav_sections_setting',
  THEME_MODE: 'theme_mode',
  THEME_COLOR: 'theme_color',
  THEME_FONT: 'theme_font',
  FONT_APP: 'font_app',
  //Banner
  BANNER_SHOP_VISIBLE: 'banner_countdown_visible',
  BANNER_SHOP_DATE: 'banner_countdown_date',
  //
  BANNER_NETWORK_VISIBLE: 'banner_network_visible',
  BANNER_NETWORK_DATE: 'banner_network_date',
  //
  BANNER_COUNTDOWN_VISIBLE: 'bannerVisible_countdown_shop',
  BANNER_COUNTDOWN_DATE: 'bannerDate_countdown_shop',

  //
  BANNER_GLOBAL_VISIBLE: 'banner_global_visible',
  BANNER_GLOBAL_DATE: 'banner_global_date',
  //Notification
  NOTIFICATION_REQUEST_PERMISSION_BANNER: 'request_permisison_banner',
  //GAME
  GAME_IS_SOUND: 'isSound',
  //REFERRAL
  REFERRAL_CODE: 'referralCode',
  REFERRAL_DATA: 'referralData'
}

export const FIREBASE_STORAGE = {
  BLOG: 'blog',
  AUTHOR: 'author',
  USER: 'user',
  SIGNATURE: 'signature',
  PRODUCT: 'product',
  DOCUMENT: 'document'
}

export const RESPONSE_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UN_PROCESSABLE_ERROR: 422,
  INTERNAL_SERVER_ERROR: 500,
  TO_MANY_REQUESTS: 429
}

export const RESPONSE_STATUS = {
  OK: 'OK',
  BAD_REQUEST: 'INVALID_ARGUMENT',
  UNAUTHORIZED: 'UNAUTHENTICATED',
  NOT_FOUND: 'NOT_FOUND',
  UN_PROCESSABLE_ERROR: 'Unprocessable Entity',
  INTERNAL_SERVER_ERROR: 'INTERNAL',
  TO_MANY_REQUESTS: 'TO_MANY_REQUESTS'
}

export const DEFAULT_LOCALE = 'en'

//
export const EN_LANGUAGE_KEY = 'en'

export const EN_LANGUAGE_TYPE = {
  key: EN_LANGUAGE_KEY,
  title: 'English'
}

export const VI_LANGUAGE_KEY = 'vi'

export const ALL_LANGUAGE_TYPE = [
  EN_LANGUAGE_TYPE,
  {
    key: VI_LANGUAGE_KEY,
    title: 'Việt Nam'
  }
]

export const LIGHT_THEME_KEY = 'light'

export const DARK_THEME_KEY = 'dark'

export const ALL_THEME_TYPE = [
  {
    key: LIGHT_THEME_KEY,
    title: 'user.light'
  },
  {
    key: DARK_THEME_KEY,
    title: 'user.dark'
  }
]

// Settings
export const PARAGRAPH_SKELETON = { rows: 6 }

export const REDUX_LOGGER = false

export const COLOR_GREEN = '#389E0D'

export const COLOR_CYAN = '#08979C'

export const COLOR_BLUE = '#1D39C4'

export const COLOR_VIOLET = '#531DAB'

export const COLOR_PINK = '#C41D7F'

export const COLOR_RED = '#CF1322'

export const COLOR_ORANGE = '#D46B08'

export const COLOR_YELLOW = '#D4B106'

export const COLOR_BLUE_LIGHT = '#00ACEE'

export const COLORS = [
  COLOR_BLUE_LIGHT,
  COLOR_GREEN,
  COLOR_CYAN,
  COLOR_VIOLET,
  COLOR_PINK,
  COLOR_RED,
  COLOR_ORANGE,
  COLOR_BLUE,
  COLOR_YELLOW
]

export const MAX_LEVEL_DISCUSSION = 3

export const VIEWPORT = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
}

export const AUTHENTICATION_PROVIDER = {
  EMAIL: 'email',
  GOOGLE: 'google',
  FACEBOOK: 'facebook'
}

export const COLLECTION_NAME = {
  USER: 'user',
  REVIEW: 'review',
  FEEDBACK: 'feedback',
  COUNTER: 'counter',
  NOTIFICATION: 'notification',
  DISCOUNT: 'discount',
  PAYMENT_PACKAGE: 'paymentPackage',
  PREDICTION_IELTS: 'prediction_ielts',
  PREDICTION_IELTS_SPEAKING: 'prediction_ielts_speaking',
  PREDICTION_IELTS_SPEAKING_DETAIL: 'prediction_ielts_speaking_detail',
  PREDICTION_IELTS_LISTENING: 'prediction_ielts_listening',
  PREDICTION_IELTS_LISTENING_DETAIL: 'prediction_ielts_listening_detail',
  PREDICTION_IELTS_READING: 'prediction_ielts_reading',
  PREDICTION_IELTS_READING_DETAIL: 'prediction_ielts_reading_detail',
  PREDICTION_IELTS_WRITING: 'prediction_ielts_writing',
  PREDICTION_IELTS_WRITING_DETAIL: 'prediction_ielts_writing_detail',

  RESULT_IELTS: 'result_ielts',
  RESULT_IELTS_DETAIL: 'result_ielts_detail'
}

export const NORMAL_SORT_BY_KEY = {
  UPDATED_DATE: 'updatedDate'
}

export const SORT_BY_KEY = {
  ASC: 'asc',
  DESC: 'desc'
}

export const ACTIVITY_SORT_BY_KEY = {
  ALL: 'all',
  INFORMATION: 'information',
  DISCUSSION: 'discussion'
}

export const REVIEW_SORT_BY_KEY = {
  ALL: 'all',
  USER: 'user',
  STATUS: 'status'
}

export const PAGINATION = {
  REVIEW: 12,
  BLOG: 3,
  PRODUCT: 10,
  USER: 10,
  DOCUMENT: 10,
  ORDER: 10,
  TRANSACTION: 10,
  NOTIFICATION: 10
}

export const ACCOUNT_TYPE = {
  USER_NORMAL: 'user-normal',
  USER_VIP: 'user-vip',
  MOD: 'mod',
  ADMIN: 'admin',
  PARTNER_USER: 'partner-user',
  PARTNER_COMPANY: 'partner-company'
}

export const ACCOUNT_STATE = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
}

export const APPROVE_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied'
}

export const REVIEW_LIST_TYPE = {
  ME: 'me',
  APPROVE: 'approve'
}

export const DATA_SOURCE_CRAWLER = {
  MY_OLD_APP: 'https://ieltssongngu.com/',
  MY_NEW_APP: 'https://ieltsprediction.com',
  OTHER: 'other'
}

export const Z_INDEX = {
  MODAL_SMALL: 2000,
  MODAL_MEDIUM: 2200,
  MODAL_LARGE: 2400,
  ANT_DROP_IMAGE: 2500,
  MODAL_DETAIL: 2500,
  ANT_TOOL_TIP: 2500,
  ANT_DROP_DOWN: 2600,
  ANT_TABLE_DRAG: 2600,
  NOTIFICATION: 3000,
  CONFIRM: 3500,
  MESSAGE_NETWORK: 10000,
  LOADING_APP: 50000
}

export const EMPTY_DATA_ID = 'empty_data_id'

export const ROUTER_PATH_NAME = {
  HOME: '/',
  RESULT: '/result',
  PREDICTION: '/prediction',
  REVIEW: '/ielts-review'
}

export const MAX_HEIGHT = {
  HEADER_NAV: 60
}

export const PAYMENT_CODE_ACTIVITY_ACTION_TYPE = {
  CREATE_PAYMENT_CODE: 'createPaymentCode',
  DELETE_PAYMENT_CODE: 'deletePaymentCode'
}

export const PAYMENT_LOG_ACTIVITY_ACTION_TYPE = {
  CREATE_PAYMENT_LOG: 'createPaymentLog',
  DELETE_PAYMENT_LOG: 'deletePaymentLog'
}

export const FEEDBACK_LIST_TYPE = {
  RATING: 'rating',
  SUPPORT: 'support',
  ERROR: 'error'
}

export const stp = 'ieltspredictionieltssongngu'

export const UPGRADE_SYSTEM_KEY = 'system'

export const UPGRADE_NORMAL_KEY = 'normal'

export const UPGRADE_CTV_KEY = 'ctv'

export const UPGRADE_REFERRAL_KEY = 'referral'

export const REFERRAL_PAYMENT_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  FRAUD: 'fraud',
  PAYED: 'PAYED'
}

export const PAYMENT_PACKAGE_APP = {
  IELTS_SONG_NGU: 'sn',
  IELTS_PREDICTION: 'pd'
}

export const PAYMENT_TYPE = {
  BANKING: 'banking',
  MOMO: 'momo'
}

export const DEVICE_USER_DATA_FIELD = {
  FINGER_PRINT: 'fingerPrint_PD',
  DEVICE_INFO: 'deviceInfo_PD'
}

export const STATUS_BOOK_LESSON = {
  PUBLISHED: 'published',
  HIDDEN: 'hidden'
}

export const RESULT_FILTER_TYPE = [
  {
    key: REVIEW_SORT_BY_KEY.ALL,
    title: 'Tất cả'
  },
  {
    key: 'time',
    title: 'Thời gian'
  }
]

export const RESULT_DISPLAY_KEY = {
  GRID: 'grid',
  TIME: 'time'
}

export const RESULT_DISPLAY_TYPE = [
  {
    key: RESULT_DISPLAY_KEY.GRID,
    title: 'Lưới'
  },
  {
    key: RESULT_DISPLAY_KEY.TIME,
    title: 'Mốc thời gian'
  }
]

export const AUDIO_JUMP_STEP = 5000
