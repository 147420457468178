/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
// eslint-disable-next-line
import produce from 'immer'
import { HYDRATE } from 'next-redux-wrapper'

// import { USER_INFO_KEY } from '../../../constants/Constants'

import { TODO_USER_UPDATE_INFO } from '../actions/type'

// The initial state of the App
export const initialState = {
  userInfo: {}
}

/* eslint-disable default-case, no-param-reassign */
const userReducer = (state = initialState, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration
    }

    return nextState
  } else {
    return produce(state, draft => {
      switch (action.type) {
        case TODO_USER_UPDATE_INFO:
          draft.userInfo = action.userInfo
          break
      }
    })
  }
}

export default userReducer
