import isEmpty from 'lodash/isEmpty'

import {
  MIN_LENGTH,
  MAX_LENGTH,
  ACCOUNT_TYPE,
  ACCOUNT_STATE,
  AUTHENTICATION_PROVIDER,
  VI_LANGUAGE_KEY,
  LIGHT_THEME_KEY,
  DEVICE_USER_DATA_FIELD
} from '../../../constants/Constants'

import { numberMinutes } from 'src/@core/utils/date'

export function validUserName(value) {
  const isEmptyString = isEmpty(value)
  const length = value.length

  const result = !isEmptyString && length >= MIN_LENGTH.NAME && length <= MAX_LENGTH.NAME

  return result
}

export function validUserPass(value) {
  const isEmptyString = isEmpty(value)
  const length = value.length
  const result = !isEmptyString && length >= MIN_LENGTH.PASSWORD

  return result
}

export function isAdmin(value) {
  return value === ACCOUNT_TYPE.MOD || value === ACCOUNT_TYPE.ADMIN
}

export function getTitleByUserType(value) {
  let title = 'userInfo.type_normal_member'
  if (value === ACCOUNT_TYPE.USER_NORMAL) {
    title = 'userInfo.type_normal_member'
  } else if (value === ACCOUNT_TYPE.USER_VIP) {
    title = 'userInfo.type_vip_member'
  } else if (value === ACCOUNT_TYPE.PARTNER_USER) {
    title = 'userInfo.type_partner_user'
  } else if (value === ACCOUNT_TYPE.PARTNER_COMPANY) {
    title = 'userInfo.type_partner_company'
  } else if (value === ACCOUNT_TYPE.MOD) {
    title = 'userInfo.type_mod_user'
  } else if (value === ACCOUNT_TYPE.ADMIN) {
    title = 'userInfo.type_admin_user'
  }

  return title
}

export function getMyUserSubData(userData) {
  return {
    _id: userData._id,
    name: userData.name,
    email: userData.email,
    avatar: userData.avatar
  }
}

export function checkVerifyAccountData(userData) {
  //
  let verify = true
  if (!userData) {
    verify = false
  } else {
    if (userData.provider === AUTHENTICATION_PROVIDER.EMAIL) {
      if (userData.emailVerified !== true) {
        verify = false
      }
    } else if (userData.provider === AUTHENTICATION_PROVIDER.FACEBOOK) {
      if (userData.email.length > 0) {
        if (userData.emailVerified !== true) {
          verify = false
        }
      }
    }
  }

  return verify
}

export function getAuthenticationTypeData(method) {
  let provider = AUTHENTICATION_PROVIDER.EMAIL
  const methodName = method.toUpperCase()

  //
  if (methodName.indexOf(AUTHENTICATION_PROVIDER.EMAIL.toUpperCase()) >= 0) {
    provider = AUTHENTICATION_PROVIDER.EMAIL
  } else if (methodName.indexOf(AUTHENTICATION_PROVIDER.GOOGLE.toUpperCase()) >= 0) {
    provider = AUTHENTICATION_PROVIDER.GOOGLE
  } else if (methodName.indexOf(AUTHENTICATION_PROVIDER.FACEBOOK.toUpperCase()) >= 0) {
    provider = AUTHENTICATION_PROVIDER.FACEBOOK
  }

  return provider
}

export function createNewUserFirestore(currentUser, provider) {
  const strDateCreated = String(new Date().getTime())

  // Create new user
  const newUserData = {
    _id: currentUser.uid,
    name: currentUser.name,
    email: currentUser.email,
    avatar: currentUser.avatar,
    provider,
    emailVerified: currentUser.emailVerified,
    createdAt: strDateCreated,
    updatedAt: strDateCreated,
    type: ACCOUNT_TYPE.USER_NORMAL,
    state: ACCOUNT_STATE.ENABLED,
    language: VI_LANGUAGE_KEY,
    theme: LIGHT_THEME_KEY,
    settings: {}
  }

  return newUserData
}

export function removeDeviceFromList(dbUser, deviceInfo) {
  let newInfo = null
  //
  if (dbUser) {
    const fieldNameFinger = DEVICE_USER_DATA_FIELD.FINGER_PRINT
    const fieldNameDevice = DEVICE_USER_DATA_FIELD.DEVICE_INFO
    //
    let newFingerPrint = []
    let newDeviceInfo = []
    if (dbUser[fieldNameFinger]) {
      newFingerPrint = [...dbUser[fieldNameFinger]]
    }
    if (dbUser[fieldNameDevice]) {
      newDeviceInfo = [...dbUser[fieldNameDevice]]
    }
    //
    const fingerPrint = deviceInfo[fieldNameFinger]
    //
    if (
      newFingerPrint.find(item => item === fingerPrint) &&
      newDeviceInfo.find(item => item['fingerPrint'] === fingerPrint)
    ) {
      newFingerPrint = newFingerPrint.filter(item => item !== fingerPrint)
      newDeviceInfo = newDeviceInfo.filter(item => item[fieldNameFinger] !== fingerPrint)

      newInfo = {}
      newInfo[fieldNameFinger] = newFingerPrint
      newInfo[fieldNameDevice] = newDeviceInfo
    }
  }
  //
  return newInfo
}

export function removeAllDeviceWithoutMe(dbUser, fingerPrint) {
  let newInfo = null
  //
  if (dbUser) {
    const fieldNameFinger = DEVICE_USER_DATA_FIELD.FINGER_PRINT
    const fieldNameDevice = DEVICE_USER_DATA_FIELD.DEVICE_INFO
    //
    let newFingerPrint = []
    let newDeviceInfo = []
    if (dbUser[fieldNameFinger]) {
      newFingerPrint = [...dbUser[fieldNameFinger]]
    }
    if (dbUser[fieldNameDevice]) {
      newDeviceInfo = [...dbUser[fieldNameDevice]]
    }
    //
    if (isEmpty(fingerPrint)) {
      newFingerPrint = []
      newDeviceInfo = []
    } else {
      newFingerPrint = newFingerPrint.filter(item => item === fingerPrint)
      newDeviceInfo = newDeviceInfo.filter(item => item[fieldNameFinger] === fingerPrint)
    }
    //
    newInfo = {}
    //
    newInfo[fieldNameFinger] = newFingerPrint
    newInfo[fieldNameDevice] = newDeviceInfo
  }
  //
  return newInfo
}

export function checkUpdateLastTime(currentDeviceInfo, fingerPrint) {
  let isValid = false

  const fieldNameFinger = DEVICE_USER_DATA_FIELD.FINGER_PRINT
  //
  const oldIndexDeviceInfo = currentDeviceInfo.findIndex(item => item[fieldNameFinger] === fingerPrint)

  if (oldIndexDeviceInfo >= 0) {
    const oldDeviceInfo = currentDeviceInfo[oldIndexDeviceInfo]
    if (oldDeviceInfo.lastTime) {
      const oldDate = new Date(parseInt(oldDeviceInfo.lastTime))
      const minutes = numberMinutes(oldDate)
      if (minutes) {
        if (minutes >= MAX_LENGTH.UPDATE_LAST_TIME) {
          //
          isValid = true
        }
      }
    }
  }
  //
  return isValid
}
